body {
  background-color: #f0f2f5;
}

ol,
ul {
  padding: 0px;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
  padding: 0;
  font-weight: 600;
}
.bg-white {
  background-color: white !important;
}

.w-100 {
  width: 100% !important;
}

.w-85 {
  width: 85% !important;
}

.w-50 {
  width: 50% !important;
}

.h-100 {
  height: 100% !important;
}

.h-100vh {
  height: 100vh;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-repeat {
  background-repeat: no-repeat;
  background-size: cover;
}

.fl {
  float: left;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
}

.flex-100 {
  flex: 0 0 100%;
}

.flex.flex-between {
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

.flex.flex-end {
  justify-content: flex-end;
}

.flex.justify-center {
  justify-content: center;
}

.flex.flex-align-center {
  align-items: center;
}

.flex.all-center {
  justify-content: center !important;
  align-items: center !important;
}

.flex.dir-column {
  flex-direction: column;
}

.f-10 {
  font-size: 10px;
}

.f-11 {
  font-size: 11px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-17 {
  font-size: 17px;
}

.f-18 {
  font-size: 18px;
}

.f-19 {
  font-size: 19px;
}

.f-20 {
  font-size: 20px;
}

.f-21 {
  font-size: 21px;
}

.f-22 {
  font-size: 22px;
}

.f-23 {
  font-size: 23px;
}

.f-24 {
  font-size: 24px;
}

.f-25 {
  font-size: 25px;
}

.text-center {
  text-align: center;
}

.text-white {
  color: white;
}

.text-dark {
  color: black;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-repeat {
  background-repeat: no-repeat;
  background-size: cover;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 10px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-15 {
  padding: 15px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px !important;
}

.pr-3 {
  padding-right: 30px !important;
}

.pr-4 {
  padding-right: 40px !important;
}

.pb-1 {
  padding-bottom: 10px !important;
}

.pl-1 {
  padding-left: 10px !important;
}

.px-2 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt-1 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.p-2 {
  padding: 20px !important;
}

.m-5 {
  margin: 5px;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr5-5 {
  margin-right: 5px !important;
}

.mr5-10 {
  margin-right: 10px !important;
}

.mr5-15 {
  margin-right: 15px !important;
}

.mr5-20 {
  margin-right: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

m-10 {
  margin: 10px;
}
.menu-btn {
  display: none !important;
}
/* max width 90% */
.max-width-90 {
  max-width: 90%;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.border {
  border: 1px solid #0000001f !important;
}

.border-right {
  border-right: 1px solid #0000001f !important;
}

.border-left {
  border-left: 1px solid #0000001f !important;
}

.border-bottom {
  border-bottom: 1px solid #0000001f !important;
}

.border-top {
  border-top: 1px solid #0000001f !important;
}

.border-none {
  border: none !important;
}

/* loader */
.loaderFixed {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #a6a8ae;
  z-index: 99999;
}

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
/* Menu */
.sider-btn {
  padding: 15px;
}
.sider-btn.open {
  padding-left: 24px;
}
.sider-btn a {
  width: 80%;
  z-index: 1000;
}
.sider-btn img {
  height: 80%;
  width: auto;
}

.top-menu {
  height: 60px;
  width: 100%;
  padding: 15px 20px;
  background: white;
  -webkit-box-shadow: 9px 3px 10px 3px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 9px 3px 10px 3px rgba(0, 0, 0, 0.12);
  box-shadow: 9px 3px 10px 3px rgba(0, 0, 0, 0.12);
}
.top-menu a {
  width: 50%;
  height: 100%;
}
.top-menu img {
  height: 95%;
  width: auto;
}
.top-menu .ant-avatar img {
  height: 100%;
  width: auto;
}
.top-menu .top-menu-navigation {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
}
.top-menu .top-menu-navigation .circle-notification {
  background: #a6a8ae;
  transition: 0.3s ease-out;
  line-height: 25px !important;
}
.top-menu .top-menu-navigation .circle-notification:hover {
  background: #171717;
  transition: 0.3s ease-out;
}

.bottom-menu {
  position: absolute;
  background: white;
  bottom: 0;
  justify-content: space-between;
  left: 0;
  width: 100%;
  padding: 10px 10px 10px 24px;
  display: flex;
  align-items: center;
}
.bottom-menu img {
  height: 20px;
}
.bottom-menu .icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: unset;
}
.bottom-menu .icons svg {
  font-size: 18px;
}
.ant-modal {
  width: 80vw !important;
}
.ant-modal-close-x {
  width: 45px !important;
  height: 45px !important;
  line-height: 31px !important;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
::-webkit-scrollbar-thumb {
  background: #126248;
}
.ant-layout-sider-collapsed .bottom-menu {
  flex-direction: column;
  padding: 10px;
}
.ant-layout-sider-collapsed .bottom-menu .icons {
  margin-top: 9px;
  flex-direction: column;
}
.ant-table-pagination.ant-pagination {
  display:none;
}
.ant-collapse{
  width:100%;
}
@media (min-width: 1200px) {
  .collapsedRight {
    padding-left: 80px;
  }

  .nonCollapsedRight {
    padding-left: 250px;
  }

  .letside {
    padding-bottom: 20px;
    position: relative;
  }
  .letside .side-menu {
    position: fixed !important;
    bottom: 0;
    height: 100vh;
    padding-bottom: 123px;
    top: 0;
    z-index: 6;
    /* clip-path: polygon(0 0, 51% 0, 100% 48%, 56% 100%, 0 100%, 0 52%); */
  }
  .letside .side-menu ul {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    direction: rtl;
    margin-top: 1rem;
  }
  .letside .side-menu ul li .anticon {
    position: relative;
    bottom: 0.3rem;
  }
  .letside .side-menu ul li span {
    color: #fff;
    font-size: 16px;
    font-weight: 100 !important;
  }
  .letside .side-menu::before {
    content: "";
    background-image: url("../../img/Main.png") !important;
    background-size: cover;
    position: absolute;
    border-left: 2px solid #a6a8ae;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    transform: rotate(180deg);
  }
  .letside .side-menu ul::-webkit-scrollbar,
  .letside .side-menu ul .ant-modal-body::-webkit-scrollbar {
    width: 0;
    display: none;
  }
  .letside .side-menu ul li {
    direction: ltr;
    text-align: inherit;
  }
}
.collapsedRight,
.nonCollapsedRight {
  transition: all 0.2s;
}
.collapsedRight .page-routes,
.nonCollapsedRight .page-routes {
  padding-top: 85px;
}
.page-routes::before,
.home-page::before {
  content: "";
  background-image: url("../../img/bg-hex.png");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.45;
}
.top-menu {
  transition: all 0.2s;
  position: fixed;
  z-index: 5;
  right: 0;
  /* width: auto; */
  top: 0;
}

.collapsedRight .top-menu {
  left: 80px;
}

.nonCollapsedRight .top-menu {
  left: 250px;
}
.top-menu::before,
.home-page {
  content: "";
  background-image: url("../../img/Main.png");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
tbody tr {
  color: #5da894;
}
.ant-table-thead > tr > th {
  color: #0a533f !important;
}
@media (max-width: 1200px) {
  .top-menu {
    position: fixed;
    right: 0 !important;
    left: 0 !important;
  }
}
.drawer ul {
  padding-bottom: 60px;
  height: 100%;
}
.profil-info {
  min-width: 300px;
  width: auto;
}
.profil-info .ant-row {
  margin: 0px -16px;
  margin-top: -12px;
  padding-left: 16px;
}

@media (max-width: 400px) {
  .profil-info {
    min-width: 250px;
  }

  .top-menu {
    padding: 15px;
  }
  .top-menu img {
    height: 75%;
    width: auto;
  }
  .top-menu .top-menu-navigation {
    right: 15px;
  }
}
#page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  font-weight: 600 !important;
}

.page-routes {
  padding: 20px;
  position: relative;
  max-width: 100%;
  height: 100%;
}
.page-routes.isMap {
  padding: 75px 0 0 !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 991px) {
  .menu-btn {
    display: block;
  }
  .ant-table-wrapper {
    background: transparent !important;
  }
  .ant-table-wrapper .ant-pagination {
    margin: 0px !important;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    background: white;
  }
  .ant-table-wrapper .ant-table {
    background: transparent !important;
    display: block;
  }
  .ant-table-wrapper .ant-table-thead {
    display: none;
  }
  .ant-table-wrapper .ant-table-thead > tr,
  .ant-table-wrapper .ant-table-tbody > tr {
    background: white;
    border-radius: 2px;
    margin-bottom: 8px;
    display: block;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .ant-table-wrapper .ant-table-thead > tr th,
  .ant-table-wrapper .ant-table-thead > tr td,
  .ant-table-wrapper .ant-table-tbody > tr th,
  .ant-table-wrapper .ant-table-tbody > tr td {
    border-bottom: 1px solid #0000001f !important;
    padding: 10px 15px !important;
    font-size: 15px !important;
    display: flex !important;
    justify-content: flex-end !important;
    text-align: center;
  }
  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td,
  .ant-table-wrapper .ant-table-tbody > tr > th,
  .ant-table-wrapper .ant-table-tbody > tr > td {
    display: block;
    width: auto !important;
    border: none;
  }
  .ant-table-wrapper .ant-table-thead > tr > th:last-child,
  .ant-table-wrapper .ant-table-thead > tr > td:last-child,
  .ant-table-wrapper .ant-table-tbody > tr > th:last-child,
  .ant-table-wrapper .ant-table-tbody > tr > td:last-child {
    border-bottom: 1px solid #eee;
  }

  .areasTable table tr:not(.ant-table-expanded-row) {
    display: flex !important;
    flex-direction: column;
  }
  .areasTable table tr:not(.ant-table-expanded-row) td:nth-child(1) {
    order: 1 !important;
    font-size: 20px !important;
  }
  .areasTable table .ant-table-expanded-row table tr td:nth-child(1) {
    order: unset !important;
    font-size: unset !important;
  }
}
@media (max-width: 420px) {
  .ant-table-wrapper .ant-table-thead > tr th,
  .ant-table-wrapper .ant-table-thead > tr td,
  .ant-table-wrapper .ant-table-tbody > tr th,
  .ant-table-wrapper .ant-table-tbody > tr td {
    padding: 10px !important;
    font-size: 13px !important;
  }
}
.fullmodal {
  max-width: unset !important;
  margin: 0 !important;
  height: 100% !important;
  width: 100% !important;
  background: white;
  padding: 0 !important;
}
.fullmodal .ant-modal-content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.fullmodal .ant-modal-content .ant-modal-body {
  overflow-y: unset !important;
  padding: 0 !important;
}
.fullmodal .ant-modal-content .ant-modal-body .modal-tabs {
  padding-top: 61px;
}
.fullmodal .ant-modal-close {
  display: none;
}
.fullmodal .ant-modal-header {
  padding: 0 !important;
}
.fullmodal .ant-modal-header .custom-modal-header {
  background: #b2a283;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
  display: flex;
  color: white;
  padding-right: 20px;
  align-items: center;
}
.fullmodal .ant-modal-header .custom-modal-header .addTask {
  margin-left: auto;
  background: #272727;
  cursor: pointer;
  padding: 20px;
  transition: 0.3s ease-out;
}
.fullmodal .ant-modal-header .custom-modal-header .addTask:hover {
  opacity: 0.8;
  transition: 0.3s ease-out;
}
.fullmodal .ant-modal-header .custom-modal-header .heading {
  font-size: 20px;
}
.fullmodal .ant-modal-header .custom-modal-header .close {
  padding: 20px;
  cursor: pointer;
  transition: 0.3s ease-out;
}
.fullmodal .ant-modal-header .custom-modal-header .close:hover {
  opacity: 0.8;
  transition: 0.3s ease-out;
}

.addTaskModal {
  min-width: 85%;
}

.salaryHistoryModal {
  min-width: 70%;
}

.mediumModal {
  min-width: 60%;
}

.d-none {
  display: none !important;
}

@media (max-width: 1200px) {
  .mediumModal {
    min-width: 70%;
  }
}
.ant-modal-content .ant-modal-body {
  max-height: 600px;
  height: auto;
  overflow-y: auto;
}
.ant-modal-content .ant-modal-body .ant-form-item {
  margin-bottom: 0 !important;
}
.ant-modal-content .ant-modal-body .formButton {
  margin-top: 35px;
  padding: 19px 5px;
}
.ant-modal-content .ant-modal-body .formButton.no-marg {
  margin-top: 0;
}

.cropModal .ant-modal-close,
.demandModal .ant-modal-close {
  display: none !important;
}

.modalButtons.purchase {
  position: absolute;
  bottom: 23px;
  width: 100%;
  left: 0;
  padding: 10px 24px;
}

.statusBox {
  padding: 5px 10px;
  color: white;
  border-radius: 50px;
}

.statusTd p {
  display: none;
}

@media (max-width: 991px) {
  .statusTd {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .statusTd div,
  .statusTd span {
    text-align: right;
  }
  .statusTd p {
    display: block;
    text-align: left;
  }
}
.page-heading {
  justify-content: space-between;
}

@media (max-width: 767px) {
  .page-heading .page-name {
    margin-bottom: 10px;
  }
  .page-heading .ant-btn {
    margin-bottom: 10px;
  }
}
.customtable2 {
  border: 1px solid #ddd;
  font-size: 14px;
  border-collapse: collapse;
  width: 100%;
  background-color: white;
}
.customtable2 tr {
  border: 1px solid #ddd;
}
.customtable2 tr td {
  padding: 10px;
  text-align: right;
}
.customtable2 tr td:first-child {
  text-align: left;
}

.padBottomButtons {
  position: "absolute";
  bottom: "20px";
  right: "40px";
}

.page {
  padding: 20px;
  background-color: white;
}

.left {
  float: left;
}

.box-currency p {
  font-weight: bold;
}

.red {
  color: red;
}

.green {
  color: #19be3e;
}

.d-block {
  display: block;
}

.edit .ant-upload-list-picture-card-container,
.edit .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 200px;
}
.edit.large .ant-upload-list-picture-card-container,
.edit.large .ant-upload.ant-upload-select-picture-card {
  height: 270px;
}

.gallery .ant-upload-list-picture-card-container {
  width: calc(33.3% - 8px);
  min-height: 200px;
}
.gallery .ant-upload.ant-upload-select-picture-card {
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #b2a283;
}

/*# sourceMappingURL=global.css.map */
