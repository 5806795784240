@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@400;500;600&display=swap");
.cursor-pointer {
  cursor: pointer;
}

body {
  background-repeat: no-repeat;
  background-size: cover;
}

.logo-main {
  z-index: 1000;
  width: 75px;
  height: 75px;
}
.slick-arrow {
  display: none !important;
}
.add-btn {
  height: 40px !important;
  padding: 6px 70px !important;
  float: right;
  margin-bottom: 1rem;
  font-size: 16px !important;
  color: #fff !important;
  border: 1px solid #126248 !important;
  background: #126248 !important;
}
.main-login {
  width: 100%;
  display: block;
  overflow: hidden;
  background-image: url("./../img/Main.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 35px;
  height: 100vh;
  position: absolute;
}
.main-login::before {
  content: "";
  background-image: url("./../img/bg-hex.png");
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.header {
  position: fixed;
  z-index: 50;
  top: 25px;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 20px;
}

.main-section {
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  z-index: 5;
}
.main-section .main-text p {
  font-weight: 300;
  font-size: 18px;
  color: #fff;
  margin-top: 15rem;
}
.main-section img {
  width: 25rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
.main-section span {
  font-size: 14px;
  color: #fff;
}
.main-section .main-link img {
  width: 55px;
  height: 55px;
}
.main-section .main-link div a {
  font-size: 13px;
  color: #fff;
}
.main-section .main-link div a:hover {
  text-decoration: none;
}
.map img {
  position: absolute;
  z-index: 3;
  top: -9rem;
  left: -3rem;
}
.brain img {
  position: absolute;
  z-index: 2;
  top: -4rem;
  right: 14%;
  width: 30rem;
  height: 30rem;
}

.hex img {
  position: fixed;
  z-index: 1;
  top: -7rem;
  left: 4rem;
}

.footer {
  padding-left: 50px;
  padding-right: 50px;
  right: 0;
  left: 0;
  width: 100%;
  bottom: 2rem;
  position: fixed;
  z-index: 50;
  font-family: "Roboto Condensed", sans-serif;
}
.footer span {
  font-size: 14px;
  color: #fff;
}
.footer img {
  padding-left: 10px;
}
.footer .mouse img {
  margin-right: 6rem;
}
.footer .social-icon {
  padding-right: 20px;
}
.footer .social-icon ul {
  list-style: none;
}
.button {
  font-family: "Exo", sans-serif;
  font-weight: 400;
  position: relative;
  z-index: 99;
  background: transparent;
  border: none;
  color: white;
}
.button:focus {
  border: none;
  outline: none;
}
.button::before {
  content: "";
  position: absolute;
  top: 0px;
  width: 25px;
  background: white;
  height: 2px;
  margin-top: -10px;
  transition: all 0.2s ease;
}
.button:hover {
  color: white !important;
}
.button:hover::before {
  width: 90%;
}
.menu-bar {
  position: fixed;
  z-index: 40;
  top: 0;
  bottom: 0;
  overflow: hidden;
  background-image: url("./../img/Main.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 45px;
  right: -6rem;
  left: 0;
}
.menu-bar .overlay {
  content: " ";
  right: 0;
  left: 0;
  position: fixed;
  z-index: 41;
  top: 0;
  width: 100%;
  bottom: 0;
  background: #0a533f;
  opacity: 0.4;
  backdrop-filter: blur(150px) !important;
}
.menu-bar .menu {
  font-family: "Roboto", sans-serif;
  position: relative;
  z-index: 43;
}
.menu-bar .menu a {
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}
.menu-bar .menu a:hover {
  text-decoration: none;
  color: #fff;
  margin-left: 20px;
}
.menu-bar .menu .line-menu {
  position: relative;
  z-index: 21;
  transition: all 1s ease;
}
.menu-bar .menu .line-menu::before {
  content: "";
  position: absolute;
  width: 0;
  z-index: -1;
  transition: all 0.3s ease;
}
.menu-bar .menu .line-menu:hover::before {
  left: -20px;
  top: -5px;
  width: 120%;
  margin-top: -5px;
  background: #28b36c;
  height: 55px;
}

.hex-menu img {
  position: absolute;
  z-index: 1;
  top: -7rem;
  right: 8rem;
}

.menu-list-map img {
  position: absolute;
  z-index: 42;
  top: 0;
  right: 10rem;
  width: 50rem;
}

@media (min-width: 1537px) {
  .logo-main {
    width: 90px;
    height: 90px;
    margin-top: 25px;
  }
  .hex img {
    top: 2rem;
    left: 14rem;
    transform: scale(1.2);
  }

  .brain img {
    top: -33rem;
    right: -17rem;
    width: 35rem;
    height: 35rem;
  }
  .footer {
    padding-left: 40px;
    padding-right: 40px;
    bottom: 3rem;
  }
  .menu-bar {
    padding: 0 55px;
    right: 0;
  }
  .menu-bar .menu {
    margin-top: 15rem;
  }
  .menu-bar .menu div {
    margin-bottom: 20px;
  }
  .menu-bar .menu a {
    font-size: 32px;
  }
  .menu-bar .overlay {
    background: black;
    opacity: 0.3;
  }

  .hex-menu img {
    position: absolute;
    z-index: 1;
    top: 5rem;
    right: 12rem;
  }

  .menu-list-map img {
    top: 2rem;
    right: 5rem;
    width: 60rem;
  }
}
@media (max-width: 1200px) {
  .web {
    display: none !important;
  }

  .mobile {
    display: block;
  }
}
@media (max-width: 769px) {
  .header {
    top: 0;
    padding: 20px 10px;
    transition: 0.2s all;
  }
  .header.header-fixed {
    background-image: url("./../img/Main.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 40px 0 black;
    transition: 0.2s all;
  }

  .footer span {
    display: none;
  }
  .footer .mouse img {
    display: none;
  }
  .footer .social-icon {
    display: none;
  }

  body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .logo-main {
    width: 55px;
    height: 55px;
  }

  .map img {
    top: -9rem;
    left: -6rem;
  }

  .hex img {
    top: 0;
    left: -2rem;
  }

  .hexagon img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    transform: rotate(-90deg) scale(1);
    top: 0;
    left: 20px;
  }

  .menu-bar {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .menu-bar {
    padding: 0 20px;
  }
  .menu-bar .menu a {
    font-size: 25px;
  }
  .menu-bar .menu .line-menu:hover::before {
    top: 0;
    height: 40px;
  }

  .hex-menu img {
    top: -2rem;
    right: 4rem;
  }

  .menu-list-map img {
    top: 7rem;
    right: 5rem;
    width: 35rem;
  }
  .menu-bar {
    padding: 0 20px;
  }
  .menu-bar .menu a {
    font-size: 15px;
  }
  .menu-bar .menu .line-menu:hover::before {
    top: -2px;
    height: 30px;
  }

  .hex-menu img {
    top: -2rem;
    right: 4rem;
  }

  .menu-list-map img {
    top: 12rem;
    right: 4rem;
    width: 23rem;
  }
}
.login-title {
  color: #fff;
}
.hexagon-title {
  z-index: 1000;
}
.hexagon-title img {
  width: 45px;
  height: 45px;
  opacity: 0.4;
  transform: rotate(90deg);
}
.title {
  color: #fff;
  font-size: 30px;
  margin-left: 1rem;
  z-index: 1000;
  font-weight: 100;
}
.customForm .ant-form .ant-form-item {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
}
.input-btn {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}
.input-btn:hover,
.input-btn:focus {
  background-color: #126248;
  border: 1px solid #126248;
}
.login-label {
  color: #fff;
  border-bottom: 1px solid #fff;
}
.login-input,
.login-label .ant-input {
  width: 100% !important;
  color: #fff !important;
  background: none !important;
  border: none !important;
}
.login-input:focus,
.ant-input:focus {
  outline: none !important ;
}
.login-input::placeholder,
.ant-input::placeholder {
  color: #c9c3c3;
}
.ant-input-password-icon {
  color: #fff !important;
  font-size: 20px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #126248;
  border-color: #fff;
}
.ant-layout-header {
  background: #126248 !important;
  color: #fff;
}
/*# sourceMappingURL=style.css.map */
