#components-layout-demo-fixed .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  background: #fff;
}

.lang_icon {
  max-height: 20px;
  position: absolute;
  top: 35px;
  right: 15px;
  width: 20px;
}
.button_icon {
  width: 15px;
  margin: 0 10px;
}

.position_relative {
  position: relative;
}
.ant-form-item-label label {
  font-weight: 600;
}

.w-100 {
  width: 100%;
}

/* margins start */
.mr-5 {
  margin-right: 5px;
}
.mb-15 {
  margin-bottom: 15px;
}

/* floats */
.fr {
  float: right;
}

.table_image {
  width: 50px;
}
